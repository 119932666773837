// i18next-extract-mark-ns-start products-fearce

import { FEARCE_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import List from '@components/List';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Finite Element Analysis</h2>
					</Trans>

					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="FEARCE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							FEARCE is a finite element (FE) pre- and post-processing
							tool written specifically to support engine and vehicle
							analysis. FEARCE acts as an interface to integrate each
							stage of an FE analysis – from assembling component
							models into larger systems, through the application of
							loads and boundary conditions, to the solution and
							post-processing of results.&nbsp;
						</p>
						<h2>How it works</h2>
						<p>
							FEARCE adds automation to these key tasks so that
							complex analyses can be performed quickly and accurately
							while ensuring common processes are reliable, repeatable
							and traceable between iterations.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<PageSection title={t("_KeyFeatures")} layout="wide">
				<Trans i18nKey="features">
					<List type="grid">
						<li>
							Integrated graphical user interface (GUI), providing a
							single environment for all key stages in the process
						</li>
						<li>
							Unique network approach allows a user to construct
							complex FE assemblies from component models
						</li>
						<li>
							Automated connectivity of interfaces between components
						</li>
						<li>
							All types of connectivity can be applied, including
							advanced non-linear interfaces, such as contact joins
							and structural or thermal gaps. Dissimilar meshes can be
							joined using multi-point constraints
						</li>
						<li>
							All connectivity is automatically checked – with
							conflicts, fixes and warnings flagged and documented
						</li>
						<li>
							Easy application of force, pressure or displacement
							loads to the system
						</li>
						<li>
							Automatic mapping of boundary conditions from external
							sources
						</li>
						<li>
							Direct mapping of loads from VECTIS, WAVE, PISDYN and
							ENGDYN
						</li>
						<li>
							Internal linear solver and translators to automatically
							set up solution decks for all major third-party FE
							packages
						</li>
						<li>
							Full suite of post-processing tools, including: Plots
							and animations; Load-case combination and factoring;
							Bore, bearing and valve distortion studies; Durability
							analyses; Noise, vibration and harshness (NVH) studies
						</li>
					</List>
				</Trans>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/rs_fearce.png" }) {
		...imageStandard
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "FEARCE" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
